<template>
  <div>
    <TopBar :title="name"></TopBar>
    <v-container class="px-9">
      <v-card tile>
        <v-container>
          <v-row no-gutters>
            <v-col>
              <v-text-field
                v-model="searchTerm"
                placeholder="Enter text to search or create a new entry"
              />
            </v-col>
          </v-row>
          <v-row v-if="filteredItems.length > 0" no-gutters>
            <v-col v-for="item in filteredItems" :key="item.id" cols="12">
              <LookupEditorListItem
                :item="item"
                :lookup-service="lookupService"
                :lookup-type="lookupType"
                @deleted="loadItems"
              />
            </v-col>
          </v-row>
          <transition name="fade">
            <v-row v-if="searchTerm && !searchTermExists">
              <v-col>
                <LookupEditorCreate
                  :value="searchTerm"
                  :lookup-service="lookupService"
                  :lookup-type="lookupType"
                  @created="loadItems"
                />
              </v-col>
            </v-row>
          </transition>
        </v-container>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import LookupEditorListItem from './LookupEditorListItem';
import LookupEditorCreate from './LookupEditorCreate';
import TopBar from '../../common/TopBar';
import api from '../../../utils/api-axios';

export default {
  components: {
    LookupEditorListItem,
    LookupEditorCreate,
    TopBar,
  },
  props: {
    lookupService: {
      type: String,
      required: true,
    },
    lookupType: {
      type: String,
      required: true,
      validator(type) {
        return [
          'categories',
          'subcategories',
          'series',
          'countries',
          'utdanningsprogram',
          'klassetrinn',
          'kompetansemaal',
          'fag',
        ].includes(type);
      },
    },
  },
  data() {
    return {
      searchTerm: '',
      items: [],
    };
  },
  computed: {
    searchTermExists() {
      return (
        this.items.filter(
          item => item.value.toLowerCase() === this.searchTerm.toLowerCase(),
        ).length > 0
      );
    },
    filteredItems() {
      return this.items.filter(option => {
        return RegExp(`.*${this.searchTerm}.*`, 'i').test(option.value);
      });
    },
    name() {
      return this.lookupType[0].toUpperCase() + this.lookupType.slice(1);
    },
  },
  created() {
    this.loadItems();
  },
  methods: {
    loadItems() {
      api.get(`/${this.lookupService}/${this.lookupType}`).then(response => {
        this.$set(this, 'items', response.data);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s ease-in-out;
}

.face-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
