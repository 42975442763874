<template>
  <LookupEditor lookup-service="hjelpesiden-service" lookup-type="countries" />
</template>

<script>
import LookupEditor from '../../components/lookups/LookupEditor';
export default {
  components: {
    LookupEditor,
  },
};
</script>

<style lang="scss" module>
// ph
</style>
