<template>
  <v-container class="text-center">
    <v-btn text :class="$style.btn" @click="create">
      <v-icon class="mr-2">mdi-plus</v-icon> {{ value }}
    </v-btn>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import axios from '../../../utils/api-axios';

export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    lookupService: {
      type: String,
      required: true,
    },
    lookupType: {
      type: String,
      required: true,
    },
  },
  methods: {
    ...mapActions(['setLoaderSnackbar']),
    create() {
      const response = axios.post(`/${this.lookupService}/${this.lookupType}`, {
        value: this.value,
      });
      const promise = response.then(() => {
        this.$emit('created');
        return `Created "${this.value}"`;
      });

      this.setLoaderSnackbar({
        text: `Creating "${this.value}"`,
        promise,
      });
    },
  },
};
</script>

<style lang="scss" module>
.btn :global(.v-btn__content) {
  text-transform: none;
}
</style>
