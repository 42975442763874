<template>
  <div v-if="isEditing" class="body-1" :class="$style.self">
    <v-text-field
      v-model="updated"
      :class="$style.input"
      autofocus
      dense
      @keydown.enter="submit"
      @keydown.esc="undo"
      @blur="onBlur"
    />
    <v-progress-circular v-if="isLoading" size="20" width="3" indeterminate />
    <template v-else>
      <v-icon v-if="hasChanged" key="submit" class="mx-1" @click="submit">
        mdi-check
      </v-icon>
      <v-icon
        v-if="hasChanged"
        key="undo"
        class="mx-1"
        title="Undo"
        @click="undo"
      >
        mdi-undo
      </v-icon>
    </template>
  </div>
  <div v-else class="body-1" :class="[$style.self, $style.display]">
    <span tabindex="0" @click="isEditing = true" @focus="isEditing = true">
      {{ current }}
    </span>
    <v-icon
      key="edit"
      :class="$style.tool"
      title="Edit"
      @click="isEditing = true"
    >
      mdi-pencil
    </v-icon>
    <v-icon
      key="delete"
      :class="$style.tool"
      class="mx-1"
      title="Delete"
      @click="showDeleteDialog"
    >
      mdi-delete
    </v-icon>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import api from '../../../utils/api-axios';

export default {
  props: {
    item: {
      type: Object,
      required: true,
      validator(item) {
        return item.id && item.value;
      },
    },
    lookupService: {
      type: String,
      required: true,
      valiadtor(service) {
        return ['hjelpesiden-service', 'stories-service'].includes(service);
      },
    },
    lookupType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isEditing: false,
      isLoading: false,
      current: null,
      updated: null,
    };
  },
  computed: {
    hasChanged() {
      return this.current !== this.updated;
    },
  },
  created() {
    this.current = this.item.value;
    this.updated = this.item.value;
  },
  methods: {
    ...mapActions(['setLoaderSnackbar']),
    submit() {
      if (!this.hasChanged) {
        this.isEditing = false;
        return;
      }

      this.isLoading = true;

      const promise = api
        .put(`/${this.lookupService}/${this.lookupType}/${this.item.id}`, {
          id: this.item.id,
          value: this.updated,
        })
        .then(response => {
          this.isEditing = false;
          this.isLoading = false;
          this.current = response.data.value;
          this.upated = response.data.value;
        });

      this.setLoaderSnackbar({
        text: `Changing "${this.current}" to "${this.updated}"...`,
        successText: `Changed "${this.current}" to "${this.updated}"`,
        promise,
      });
    },
    undo() {
      this.updated = this.current;
      this.isEditing = false;
    },
    showDeleteDialog() {
      this.$modal
        .loadConfirmation({
          header: 'Are you sure you want to delete this?',
          text: 'This action can not be undone.',
        })
        .then(confirmed => {
          if (confirmed) this.deleteLookup();
        });
    },
    deleteLookup() {
      const promise = api
        .delete(`/${this.lookupService}/${this.lookupType}/${this.item.id}`)
        .then(() => this.$emit('deleted'));

      this.setLoaderSnackbar({
        text: `Deleting ${this.current}...`,
        successText: `Deleted ${this.current}`,
        promise,
      });
    },
    onBlur() {
      if (!this.hasChanged) {
        this.isEditing = false;
      }
    },
  },
};
</script>

<style lang="scss" module>
.self {
  height: 40px;

  &.display {
    line-height: 40px;
  }
}

.self .tool {
  display: none;
}

.self:hover .tool {
  display: initial;
  cursor: pointer;
}

.input {
  max-width: 250px;
  display: inline-block;
  margin-right: 4px;
}
</style>
