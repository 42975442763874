<template>
  <div :class="$style.self">
    <v-container class="py-2 my-0 px-9">
      <v-row justify="end" align-content="center" align="center">
        <v-col v-if="$slots.left" cols="auto">
          <slot name="left"></slot>
        </v-col>
        <v-col>
          <span class="title">{{ title }}</span>
        </v-col>
        <v-col cols="auto">
          <slot></slot>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" module>
.self {
  background-color: $theme-color-surface;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border-bottom: $theme-border;
  margin-bottom: 16px;
  & :global(.v-btn) {
    &:not(:last-of-type) {
      margin-right: 8px;
    }
  }
}
</style>
